import * as React from "react"
import Layout from "../components/layout";
import {getImage} from "gatsby-plugin-image";
import {graphql} from "gatsby";
import IgPost from "../components/ig-post";
import {IoLogoInstagram} from "react-icons/io5";

// markup
const GalleryPage = ({data}) => {

  const images = data.allInstagramContent.edges.map(edge => ({
    image: getImage(edge.node.localFile),
    caption: edge.node.caption,
    url: edge.node.permalink
  }));

  return (
    <Layout title="Gallery">
      <div className="px-0 mx-0 grid grid-cols-1 xs:grid-cols-2 sm:grid-cols-3 xl:grid-cols-4">
        { images.map((ig, index) => (
          <IgPost key={`ig-post-${index}`} caption={ig.caption} image={ig.image} url={ig.url}/>
        ))}
      </div>
      <div className="container py-20 flex justify-center">
        <a href="https://www.instagram.com/scribblex_app/"
           target="_blank"
           rel="noreferrer">
        <button className="font-normal py-4 text-xl flex items-center no-underline filter">
          <span>Find more drawings on Instagram</span>
          <IoLogoInstagram className="ml-2"/>
        </button>
      </a>
    </div>
</Layout>
)
}

export default GalleryPage

export const query = graphql`
  query gallery {
    allInstagramContent(limit: 12) {
      edges {
        node {
          caption
          media_url
          permalink
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`
