import React, {useState} from "react";
import {GatsbyImage} from "gatsby-plugin-image";
import {IoLogoInstagram} from "react-icons/io5";

const IgPost = ({url, caption, image}) => {

  const [isHovered, setHovered] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  }

  const handleMouseLeave = () => {
    setHovered(false);
  }

  return (
    <div className="relative"
         tabIndex={1}
         onMouseOver={handleMouseEnter}
         onFocus={handleMouseEnter}
         onBlur={handleMouseLeave}
         onMouseLeave={handleMouseLeave}>
      <div className={`transition filter ${isHovered ? 'brightness-25' : 'brightness-100'}`}>
        <GatsbyImage alt={caption}
                     image={image}/>
      </div>
      <a href={url}
         target="_blank"
         rel="noreferrer"
         className={`
          absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2
          rounded px-2 py-3 border 
          flex items-center transition 
          ${isHovered ? 'visible' : 'invisible'} 
          text-white no-underline
          hover:bg-white hover:bg-opacity-20
        `}>
        <p>See on Instagram</p>
        <IoLogoInstagram className="ml-2 text-xl"/>
      </a>
    </div>
  )
}

export default IgPost
